<template>
    <div>
        <b-row class="content-header">
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2>Privacy Policy</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-card>
            <p>
                This privacy policy may be changed at any time. Agreement
                relating to confidentiality.
            </p>

            <h5>COOKIES USAGE</h5>
            <p>
                Leechall.io use the cookies to keep User logged in. When you
                sign out, this cookie will be deleted. We do not use them to
                track your activities or for any other purposes.
            </p>

            <h5>THE DATA WE COLLECT</h5>
            <ul>
                <li>
                    <strong>IP address: </strong>We save the IPs and their
                    geolocation of the user in our databases for internal usage
                    only. (Abuses, Account protection, Bandwidth performances
                    ...)
                </li>
                <li>
                    <strong>Email & Password: </strong>Your email address will
                    be stored on our server when you sign up for an account.
                    Your password will be encrypted with a strong algorithm
                    before getting recorded and cannot be reversible (even for
                    us).
                </li>
                <li>
                    <strong>Data record of requests: </strong>Our servers logs
                    the IPs, the date of your requests and record the errors
                    (Data sent, dates, IPs, responses) for debug or support.
                </li>
                <li>
                    <strong>Browser and OS: </strong>We record the browser and
                    OS name on our databases for internal usage only.
                </li>
            </ul>

            <h5>PAYMENT INFORMATION</h5>
            <p>
                We use 3rd sites to handle your payment. Your information will
                be processed via https and totally encrypted.
            </p>

            <h6>DATA SHARING WITH THIRD SITES/COMPANIES</h6>
            <p>
                Any data of yours will only be saved on our server. We won't
                share or provide it to anyone/any companies. If you wish to
                delete your account & other relating data, please contact us.
            </p>
        </b-card>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
    },
};
</script>

<style></style>
